import React from 'react'
import styled from 'styled-components'
import Button from '../buttonComponents/Button'
import LoanCompanyImage from '../images/LoanCompanyImage'
import { device } from '../../utils/responsive'

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
`
const Thead = styled.thead`
  @media ${device.mobileL} {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`
const Tbody = styled.tbody``
const Tr = styled.tr`
  :hover {
    background-color: #dbdede;
  }
  @media ${device.mobileL} {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 2rem;
  }
`
const Th = styled.th`
  border-bottom: 6px solid ${props => props.theme.secondary};
  height: 50px;
  @media ${device.mobileL} {
    display: block;
    clear: both;
    width: 95%;
  }
`
const Td = styled.td`
  border-bottom: 3px solid ${props => props.theme.primary};
  text-align: center;
  padding: 0.2rem 0.5rem;
  @media ${device.mobileL} {
    border-bottom: ${props =>
      props.last ? '0px solid black' : '1px solid #ddd'};
    display: block;
    text-align: right;
    margin-bottom: 1rem;
    ::before {
      content: attr(data-label);
      float: left;
    }
  }
`

const LoanTable = props => {
  const loans = props.loans
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Lainaaja</Th>
          <Th>Luottoa</Th>
          <Th>Maksa takaisin(kk)</Th>
          <Th>Kulut</Th>
          <Th>Ikäraja</Th>
          <Th>Hae luottoa</Th>
        </Tr>
      </Thead>
      <Tbody>
        {loans.map(n => (
          <Tr>
            <Td last>
              <LoanCompanyImage imgName={n.node.filter.toLowerCase()} />
            </Td>
            <Td data-label="Luottoa">
              {n.node.minLoan} - {n.node.maxLoan}€
            </Td>
            <Td data-label="Maksa takaisin(kk)">
              {n.node.minLoanTime} - {n.node.maxLoanTime}kk
            </Td>
            <Td data-label="Kulut">{n.node.interes}%</Td>
            <Td data-label="Ikäraja">{n.node.age}v</Td>
            <Td last>
              <Button url={n.node.link} external={true}>
                Hae luottoa
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export default LoanTable
