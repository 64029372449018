import React from 'react'
import GlobalStyle from '../components/GlobalStyle'
import styled from 'styled-components'
import { H1 } from '../components/layout/headers/Headers'
import { graphql } from 'gatsby'
import DropdownSelector from '../components/dropdownSelector/DropdownSelector'
import Menubar from '../components/menuBar/Menubar'
import { device } from '../utils/responsive'
import ButtonContainer from '../components/buttonComponents/ButtonContainer'
import LoanTable from '../components/table/LoanTable'
import Seo from '../components/seo/Seo'
import { useLoansJson } from '../hooks/useLoansJson'
import { useLoanCompareMd } from '../hooks/useLoanCompareMd'

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.theme.sidebarRatio};
  @media ${device.mobileL} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`

const MainArea = styled.div`
  background-color: ${props => props.theme.bgContainer};
  padding: 2rem;
  margin: 1rem;
`

const Sidebar = styled.div`
  background-color: ${props => props.theme.bgContainer};
  margin: 1rem;
`
const TableContainer = styled.div`
  margin: 3rem;
  @media ${device.mobileL} {
    margin: 0.5rem;
  }
`

const Template = ({ data }) => {
  let { markdownRemark: post } = data
  post = Object.assign({}, post, post.fields, post.frontmatter)
  const pageData = data.markdownRemark
  const loanSums = useLoanCompareMd()
  const loans = useLoansJson(null, null)

  return (
    <div>
      <Seo isBlogPost={true} postData={post} postImage={post.img} />
      <GlobalStyle>
        <Menubar />
        <H1>{pageData.frontmatter.title}</H1>
        <TableContainer>
          <LoanTable loans={loans} />
        </TableContainer>
        <ContentContainer>
          <div>
            <ButtonContainer
              filter="loancompare"
              data={loanSums}
              currentTitle={pageData.frontmatter.title}
            />
            <MainArea
              dangerouslySetInnerHTML={{ __html: pageData.html }}
            ></MainArea>
          </div>
          <Sidebar>
            <DropdownSelector sidebar={true} />
          </Sidebar>
        </ContentContainer>
      </GlobalStyle>
    </div>
  )
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        title
        desc
        loanType
        img
        metaDesc
        metaTitle
        type
      }
      fields {
        newPath
        newTitle
        newMetaTitle
        newMetaDesc
      }
    }
  }
`

export default Template
